.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;

  .nav-links {
    display: flex;
    list-style: none;

    li {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      .nav-link {
        text-decoration: none;
        color: #333;

        &:hover {
          color: #666;
        }
      }
    }
  }
}
  
  @media screen and (max-width: 768px) {
    .navbar {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
  
      .nav-links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
  
        li {
          width: 100%;
          margin: 1rem 0;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          .nav-link {
            width: 100%;
            padding: 1rem;
            text-align: center;
            background-color: #f2f2f2;
            color: #333;
            border-radius: 5px;
            transition: background-color 0.3s ease-in-out;
  
            &:hover {
              background-color: #ddd;
            }
  
            &.active {
              background-color: #333;
              color: #fff;
            }
          }
        }
      }
    }
  }
  
  header .dark {
    background-color: #333;
    border-bottom: 1px solid #444;
    color: white;
  }

  .dark .navbar {
    background-color: #333!important;
  }
  
  
  .dark .navbar .nav-link {
    color: #fff;
  }
  
  .dark .navbar button {
    color: #fff;
    background-color: #333;
  }
  
  .dark .navbar button:hover {
    background-color: #444;
  }

  .dark .navbar .collapse {
    color: #fff;
    background-color: #333;
  }

  .dark nav ul li a {
    color: #fff;
    }
    
    .dark nav ul li a:hover {
    color: #ccc;
    }
    
    .dark nav button {
    background-color: #fff;
    color: #333;
    }

    .nav-item {
        cursor: pointer;
    }

    .navbar-toggler {
        position: relative;
        z-index: 1;
      }