.projects {
    padding-top: 80px;
    padding-bottom: 80px;
  
    .project {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
  
      img {
        max-width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 20px;
      }
  
      .project-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        h3 {
          margin-bottom: 10px;
          font-size: 24px;
        }
  
        p {
          margin-bottom: 10px;
        }
  
        a {
          text-decoration: none;
          color: #333;
          background-color: #f8f8f8;
          padding: 10px 20px;
          border-radius: 5px;
          display: inline-block;
        }
      }
    }
  
    @media screen and (min-width: 768px) {
      .project {
        flex-direction: row;
        align-items: center;
  
        img {
          max-width: 50%;
          margin-right: 40px;
          margin-bottom: 0;
        }
      }
    }
  }