.team {
  background-color: #f2f2f2;

  .container h2 {
    padding-bottom: 30px;
  }

  .member {
    display: flex;
    margin-bottom: 40px;

    .circular {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 30px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .member-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 20px;

      h3 {
        margin-top: 0;
      }

      p {
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        list-style: none;
        padding: 0;

        li {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: #666;
            transition: all 0.3s ease;

            &:hover {
              color: #000;
              background-color: #f2f2f2;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}