.home {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  
    .container {
      text-align: center;
      position: relative;
      z-index: 1;
  
      h1 {
        font-size: 4rem;
        color: white;
        word-wrap: break-word;
        letter-spacing: -2px;
      }
  
      p {
        font-size: 1.5rem;
        color: white;
        margin-bottom: 2rem;
      }
  
      .learn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease;
  
        span {
          margin-right: 1rem;
        }
  
        i {
          margin-left: 1rem;
          transition: all 0.3s ease;
        }
  
        &:hover {
          transform: translateY(-10px);
  
          i {
            transform: translateY(10px);
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .home .container h1 {
        font-size: 4rem;
        color: white;
        word-wrap: break-word;
        letter-spacing: -2px;
      }
    }
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }
  
    &.dark {
      &:before {
        background-color: rgba(0, 0, 0, 0.8);
      }
  
      .container {
        h1,
        p,
        .learn-more {
          color: white;
        }
      }
    }
  }

  .TypingText {
    display: inline-block;
    overflow: hidden;
    border-right: .15em solid rgb(86, 114, 243);
    white-space: nowrap;
    letter-spacing: .15em;
    animation: typing 5s steps(30, end), blink-caret .5s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(85, 86, 178) }
  }