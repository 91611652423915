body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  main {
    padding: 20px;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.5;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  button[type='submit'] {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
    .dark {
    background-color: #333;
    color: #fff;
    }
    
    .dark form label {
    color: #fff;
    }
    
    .dark input,
    .dark textarea {
    background-color: #444;
    color: #fff;
    border: 1px solid #555;
    }
    
    .dark button[type='submit'] {
    background-color: #fff;
    color: #333;
    }
    
    .dark .team {
    background-color: #444;
    color: #fff;
    }
    
    .dark .member-details ul li a {
    color: #fff;
    }
    
    .dark .project-details a {
    color: #fff;
    background-color: #555;
    }

      @media (max-width: 768px) {
        .navbar {
          flex-direction: column;
      
          .dark-mode-toggle {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
      

.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .scroll-to-top-button:hover {
    background-color: #666;
  }

  .contact {
    padding-top: 80px;
    padding-bottom: 80px;
  
    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
  
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      label {
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      input,
      textarea {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 5px;
        background-color: #f5f5f5;
      }
  
      textarea {
        height: 200px;
      }
  
      button[type='submit'] {
        background-color: #333;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: #666;
        }
      }
    }
  }

  footer {
    margin-top: auto;
  }