.company {
    padding: 80px 0;
  
    .row {
      display: flex;
      align-items: center;
  
      .col-md-6 {
        img.company-image {
          width: 100%;
          max-width: 600px;
          height: auto;
          margin-bottom: 40px;
        }
      }
  
      .col-md-6 {
        h2 {
          margin-top: 0;
        }
  
        p {
          margin-bottom: 20px;
        }
      }
    }
  
    @media (max-width: 768px) {
      .row {
        flex-direction: column;
  
        .col-md-6 {
          img.company-image {
            width: 100%;
            max-width: none;
            height: auto;
            margin-bottom: 20px;
          }
        }
  
        .col-md-6:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }